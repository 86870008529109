import {BLOB_DOCX, BLOB_PDF, DOC, DOCX, PDF} from "@/domain/Entities/Upload/extensions";

const COMPATIBLE_WITH_THE_EDITOR = [DOC, DOCX, PDF];

export default {
    methods: {
        isCompatibleWithTheEditor(fileName) {
            const extension = fileName.split('.').pop();

            return COMPATIBLE_WITH_THE_EDITOR.includes(extension);
        },
        convertArrayBufferToDocxBlob(arrayBuffer) {
            return new Blob([new Uint8Array(arrayBuffer)], {type: BLOB_DOCX});
        },
        convertArrayBufferToPdfBlob(arrayBuffer) {
            return new Blob([new Uint8Array(arrayBuffer)], {type: BLOB_PDF});
        },
        removeFileNameExtension(fileName) {
            return fileName.split('.').shift();
        },
        getFileExtension(fileName) {
            return fileName.split('.').pop();
        }
    }
}
